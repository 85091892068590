<template>
  <v-card flat>
    <v-card-text>
      <v-layout
        v-if="!elevator.unloadedAt"
        justify-center align-center
        class="mt-2"
      >
        <div>Aucune attestation de collecte disponible</div>
      </v-layout>
      <v-list v-else>
        <v-subheader>Liste des types de déchets déchargés</v-subheader>
        <v-divider />
        <template
          v-for="type in wasteTypes">
          <v-list-item
            :key="type.id">
            <v-list-item-content>
              <v-list-item-title>
                {{ type.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary" outlined
                @click="downloadHandlingCertificate(type)"
              >
                <v-icon left>mdi-download</v-icon>
                Télécharger
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="type.id + '_divider'" />
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    elevator: {
      type: Object,
      required: true
    }
  },

  computed: {
    wasteTypes () {
      let types = {}
      this.elevator.fillingParts.forEach(part => {
        let type = part.containerCycle.container.defaultWasteType
        if (!types[type.id]) {
          types[type.id] = type
        }
      })
      return Object.entries(types).map(type => type[1])
    }
  },

  methods: {
    downloadHandlingCertificate (type) {
      this.$store.dispatch(
        'elevators/generateHandlingCertificate',
        { elevator: this.elevator, type }
      ).then(res => {
        window.open(res.url, '_blank')
      }).catch(err => {
        console.error(err)
        this.$store.commit('alert/add', {
          color: 'error',
          text: "Erreur de génération de l'attestation de collecte"
        })
      })
    }
  }
}
</script>
